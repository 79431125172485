<script>
export default {
  methods: {
    handleClick () {
      const rowData = this.params.data
      this.params.onClick(rowData)
    }
  }
}
</script>

  <template>
    <div>
      <button @click="handleClick">Dupliquer</button>
    </div>
  </template>
