<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  methods: {
    copyUUIDToClipboard (uuid) {
      navigator.clipboard.writeText(uuid)
        .then(() => {
          this.$buefy.notification.open({
            message: 'Identifiant unique copié dans le presse-papiers.',
            type: 'is-success',
            position: 'is-bottom',
            duration: 5000
          })
        })
    }
  }
}
</script>

<template>
  <div class="space-y-2 py-4 whitespace-normal text-lg">
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2 ">UUID</div>
      <div class="w-4/5 select-all" @click="copyUUIDToClipboard(params.data.uuid)">{{ params.data.uuid }}</div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Titre</div>
      <div class="w-4/5">{{ params.data.title }}</div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Mots-clés</div>
      <div class="w-4/5">
        {{ params.data.keywords.join(', ') }}
      </div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Article</div>
      <div
        class="w-4/5 ql-editor p-0"
        v-html="params.data.body"
      />
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Pièces jointes</div>
      <div class="w-4/5">
        <ul>
          <li v-for="file in params.data.files" :key="file.id">
            <a :href="file.url" target="_blank">
              <b-icon icon="fas fa-eye" class="mr-2" />{{ file.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div></template>
