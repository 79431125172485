<script>
export default {
  name: 'PageSubmenu',
  props: {
    links: {
      type: Array
    }
  },
  data () {
    return {
      roles: this.$store.getters.roles,
      currentRoutePath: this.$route.path
    }
  },
  created () {

  }
}
</script>

<template>
  <div class="bg-primary-400 px-2 flex py-4 w-full justify-between items-center flex-wrap overflow-x-auto overflow-y-visible">
    <nav class="flex gap-4" aria-label="Tabs">
      <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->
      <div
        v-for="(link, key) in links"
        :key="key"
        class="flex-shrink-0"
      >
        <router-link
          v-if="link && link.roles.some(role => $store.getters.roles.includes(role))"
          :to="{ path: link.path, query: link.query }"
          tag="a"
          class="text-gray-200 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
          exact-active-class="bg-indigo-100 text-indigo-700"
        >
          {{ link.label }}
        </router-link>
      </div>
    </nav>
  </div>
</template>
