<script>
import { mapGetters } from 'vuex'
import PageSubmenu from '@/components/PageSubmenu.vue'
import { http } from '@/services/api.service'
export default {
  components: { PageSubmenu },
  data () {
    return {
      hasInactiveArticles: false
    }
  },
  computed: {
    ...mapGetters(['domain']),
    links () {
      const links = [
        {
          label: `Actif`,
          path: '/app/articles',
          query: { status: 'active' },
          roles: ['admin', 'director', 'community-manager', 'operator']
        }
      ]
      if (this.hasInactiveArticles) {
        links.push({
          label: `Inactif`,
          path: '/app/articles',
          query: { status: 'inactive' },
          roles: ['admin', 'director', 'community-manager', 'operator']
        })
      }
      if (this.domain?.slug !== 'media-mvp') {
        links.push({
          label: `Média Social`,
          path: '/app/articles/media-mvp',
          roles: ['admin', 'director', 'community-manager', 'operator']
        })
      }
      return links
    }
  },
  created () {
    this.checkInactiveArticles()
  },
  methods: {
    checkInactiveArticles () {
      http.get('/article', {
        params: {
          status: 'inactive'
        }
      })
        .then(res => {
          this.hasInactiveArticles = res.data && res.data.length > 0
        })
        .catch(err => {
          console.error(err)
          this.hasInactiveArticles = false
        })
    }
  }
}
</script>

<template>
  <div>
    <PageSubmenu :links="links" />
  </div>
</template>
